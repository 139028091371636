export const environment = {
  production: false,
  domain: 'localhost:4200',
  region: 'ap-northeast-1',
  api_base_url: 'https://develop.apisec.online-pci.net/v1/',
  chime_url: 'https://develop.apisec.online-pci.net/v1/chime/',
  userPoolIdForPharmacy: 'ap-northeast-1_Rtj5Yj3QT',
  clientIdForPharmacy: '5bjau86tnrm5qbf010jsr9p527',
  userPoolIdForPharmacist: 'ap-northeast-1_Fv5aDWWYL',
  clientIdForPharmacist: '71h8s3g4k0c216mj9tcscrd9uf',
  title: 'ASTEM開発環境',
  windowTitle: 'Connect Online (ASTEM develop)',
  firebase: {
    apiKey: 'AIzaSyBmvOHWh4VLBbZhR33vbS4DBezAavHLMn8',
    authDomain: 'connect-online-3520a.firebaseapp.com',
    projectId: 'connect-online-3520a',
    storageBucket: 'connect-online-3520a.appspot.com',
    messagingSenderId: '585045901396',
    appId: '1:585045901396:web:2d003a5631d47ddf462dc1',
    measurementId: 'G-LV945C3Z1X',
  },
};
